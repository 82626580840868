<template>
  <div
    class="hm-table__wrapper"
    :class="{
      'hm-form__element-wrapper--error': !valid,
    }"
  >
    <div v-if="!hideName" class="hm-table__title">
      {{ name }}
    </div>
    <div class="hm-table__wrap">
      <table
        class="hm-table"
        :class="{
          'hm-table_error': !valid,
        }"
      >
        <tr>
          <th v-for="item in fields" :key="item.id">
            {{ item.name }}
            <span v-if="item.required">*</span>
          </th>
          <th colspan="2" />
        </tr>
        <tr v-for="row in rows" :key="row.id">
          <td v-for="item in row.fields" :key="item.id">
            {{ formattedValue(row.value[item.id], item) }}
          </td>
          <td class="action">
            <div @click="editRow(row.id)">
              <esmp-icon name="edit" />
            </div>
          </td>
          <td class="action">
            <div
              v-if="rows && rows.length > 1"
              @click="removeRow(row.id)"
            >
              <esmp-icon name="trash" />
            </div>
          </td>
        </tr>
      </table>
    </div>
    <esmp-button
      class="add-row"
      size="small"
      @click="addRow"
    >
      Добавить строку
    </esmp-button>
    <esmp-modal
      v-model="showModal"
      title="Редактирование"
    >
      <validation-observer ref="form">
        <hm-form-fields
          :key="editedItemId"
          class="edited-item"
          :fields="editedItemFields"
          v-model="editedItemValue"
          @change="onChange"
        />
      </validation-observer>
      <template #footer>
        <esmp-button @click="save" size="small">
          Сохранить
        </esmp-button>
      </template>
    </esmp-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { uniqueId } from 'lodash';
import HmFormFields from '../HmFormFields.vue';

export default {
  name: 'HmTable',
  components: { HmFormFields },
  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },
    value: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    hideName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      rows: this.value && this.value.length ? this.value : [{ id: uniqueId(), fields: [...this.fields], value: {} }],
      editedItemFields: [],
      editedItemValue: {},
      editedItemId: null,
      valid: true,
    };
  },
  methods: {
    async save() {
      await this.$refs.form.validate().then((success) => {
        this.valid = success;
        if (success) this.showModal = false;
      });
    },
    addRow() {
      this.rows.push({ id: uniqueId(), fields: [...this.fields], value: {} });
    },
    editRow(id) {
      const row = this.rows.find((el) => el.id === id);
      this.editedItemId = id;
      this.editedItemFields = [...row.fields];
      this.editedItemValue = { ...row.value };
      this.showModal = true;
    },
    removeRow(id) {
      this.rows = this.rows.filter((el) => el.id !== id);
      this.$emit('input', this.rows);
    },
    onChange(v) {
      this.rows = this.rows.map((el) => ({
        id: el.id,
        fields: el.fields,
        value: el.id === this.editedItemId ? v : el.value,
      }));
      this.$emit('input', this.rows);
    },
    formattedValue(value, field) {
      if (!value) return '';
      if (field.class === 'graphCalendar') {
        const val = () => {
          // Если mode = single
          if (!Array.isArray(value)) {
            return dayjs(value).format('DD.MM.YYYY');
          }
          return value.map((item) => dayjs(item).format('DD.MM.YYYY')).join(' - ');
        };
        return val();
      }
      if (field.class === 'checkboxGroup') {
        return field.listValues.filter((el) => value.includes(el.id)).map((el) => el.name).join(', ');
      }
      if (field.class === 'radioGroup' || field.class === 'select' || field.class === 'priority') {
        return field.listValues.find((el) => el.id === value).name;
      }
      if (field.class === 'search') {
        return value.map((el) => el.fullName).join(', ');
      }
      if (field.class === 'network') {
        if (value.type === 'host') {
          return value.ipAddress;
        }
        if (value.type === 'network') {
          return `${value.ipAddress} / ${value.netMask}`;
        }
        if (value.type === 'range') {
          return `${value.startIpAddress} - ${value.endIpAddress}`;
        }
        return 'Интернет';
      }
      return value;
    },
  },
  watch: {
    value: {
      handler(v) {
        this.rows = v;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
.hm-table {
  width: 100%;
  border-spacing: 0;
  border-style: hidden;
  border-collapse: collapse;
  &__title {
    margin: 0 0 10px 0;
  }
  &__wrap {
    border-radius: 4px;
    box-shadow: 0 0 0 1px #ddd;
    width: 100%;
    overflow-x: auto;
  }
  td, th {
    font-weight: normal;
    padding: 5px;
    border: 1px #ddd solid;
  }
  &_error {
    box-shadow: 0 0 0 1px $color-red;
    td, th {
      border: 1px $color-red solid;
    }
  }
}
.action {
  width: 35px;
  div {
    cursor: pointer;
  }
}
.add-row {
  margin: 20px 0 0 0;
}
.edited-item ::v-deep .hm-form {
  &__element {
    margin: 26px 0 0 0;
    &:first-child {
      margin: 0;
    }
    p {
      margin: 0 0 18px 0;
    }
    a {
      color: #9466ff;
    }
    &--hidden {
      display: none;
    }
    &-label {
      margin: 0 0 8px 0;
      transition: all ease 0.3s;
    }
    &-hint {
      margin: 8px 0 0 0;
      font-size: 12px;
      line-height: 16px;
      transition: all ease 0.3s;
    }
    &-wrapper_error {
      .hm-form__element-label, .hm-form__element-hint {
        color: $color-red;
      }
      .esmp-upload-drag {
        border-color: #ff0c0ccc;
      }
      .esmp-input .esmp-input-element,
      .esmp-textarea__input,
      .esmp-select-head {
        border: 1px #ff0c0ccc solid;
      }
    }
  }
}
</style>
